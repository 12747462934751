import React from 'react';
import styles from './GreeterScreen.module.scss';

import Greeter from './Greeter/Greeter';
import ClickMe from './ClickMe/ClickMe';
import GreeterImage from '../../assets/images/bg1.png';
import ParallaxBackground from '../ParallaxBackground/ParallaxBackground';

const GreeterScreen = () => (
  <section className={styles.wrapper} id='home'>
    <ParallaxBackground image={GreeterImage} secondary />
    <Greeter />
    <ClickMe />
  </section>
);

export default GreeterScreen;
