import React from 'react';
import PropType from 'prop-types';
import styles from './Subtitle.module.scss';

const Subtitle = ({children, secondary}) => (
  <div className={`${secondary ? styles.wrapperGreeter : styles.wrapper } to-animate`}>
    {children}
  </div>
);

Subtitle.propTypes = {
  children: PropType.element.isRequired,
  secondary: PropType.bool,
}

export default Subtitle;
