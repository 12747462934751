import React from 'react';
import styles from './Greeter.module.scss';

import Title from '../../Title/Title';
import Subtitle from '../../Subtitle/Subtitle';

const Greeter = () => (
  <div className={styles.wrapper}>
    <Title>Sweet Accordion Festival</Title>
    <Subtitle secondary>
      <>
        <span>JEDYNY TAKI FESTIVAL</span>
        <span>&nbsp;W KUJAWSKO-POMORSKIEM</span>
      </>
    </Subtitle>
  </div>
);

export default Greeter;