import React from 'react';
import styles from './Menu.module.scss';
import { Link } from 'react-scroll';

class Menu extends React.Component {
  constructor() {
    super();
    window.addEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const el = document.getElementById("menu-wrapper");
    const logo = document.getElementById('logo-image');
    const changePoint = logo.clientHeight;
    const windowPosition = window.scrollY;

    if (windowPosition > changePoint) {
      el.classList.add(styles.scrolled);
    } else {
      el.classList.remove(styles.scrolled);
    }
  }

  render() {
    return (
      <ul className={styles.wrapper} id="menu-wrapper">
        <li>
          <Link to="home" smooth={true} duration={700}>
            HOME
          </Link>
        </li>
        <li>
          <Link to="about" smooth={true} duration={700}>
            O FESTIVALU
          </Link>
        </li>
        <li>
          <Link to="bands" smooth={true} duration={700}>
            ZESPOŁY
          </Link>
        </li>
        <li>
          <Link to="tickets" smooth={true} duration={700}>
            BILETY
          </Link>
        </li>
        <li>
          <Link to="sponsors" smooth={true} duration={700}>
            SPONSORZY
          </Link>
        </li>
      </ul>
    );
  }
}

export default Menu;
