import React from 'react';
import PropType from 'prop-types';

import styles from './Screen.module.scss';
import ParallaxBackground from '../ParallaxBackground/ParallaxBackground';

const Screen = ({ children, bgImage, id, secondary, style }) => (
  <section>
    {!secondary ? (
      <div className={styles.wrapper} id={id}>
        <div className={styles.content}>{children}</div>
        <div className={styles.image}>
          <ParallaxBackground image={bgImage} style={style} />
        </div>
      </div>
    ) : (
      <div
        className={styles.wrapperSecondary}
        id={id}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className={styles.content}>{children}</div>
      </div>
    )}
  </section>
);

Screen.propTypes = {
  children: PropType.element.isRequired,
  id: PropType.string.isRequired,
  bgImage: PropType.string,
  secondary: PropType.bool,
  style: PropType.object
};

Screen.defaultProps = {
  bgImage: null,
  secondary: false
};

export default Screen;
