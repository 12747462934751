import React from 'react';
import PropType from 'prop-types';
import styles from './MenuShadow.module.scss';

class ShadowTop extends React.Component {
  constructor(props) {
    super(props);
    window.addEventListener('scroll', this.handleScroll);

    setTimeout(() => {
      this.handleScroll();
    }, 1);
  }

  handleScroll = () => {
    const changePoint = document.getElementById('logo-image').clientHeight;
    const el = document.querySelectorAll(`.${styles.wrapper}`)[0];
    const toTop = window.scrollY;

    el.style.opacity =
      toTop > changePoint ?
      1 : 0
  }

  render() {
    return (
      <div className={styles.wrapper}></div>
    );
  }
};

ShadowTop.propTypes = {
  children: PropType.element,
};

ShadowTop.defaultProps = {
  children: null,
}

export default ShadowTop;
