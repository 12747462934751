import React from "react";
import styles from "./SponsorsScreen.module.scss";
import Title from "../Title/Title";

function shuffle(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

const sponsors = shuffle([
  require("../../assets/images/sponsors/epros.jpg"),
  require("../../assets/images/sponsors/pierwsze-skrzypce.png"),
  require("../../assets/images/sponsors/kaczmarek-electric.jpg"),
  require("../../assets/images/sponsors/sklep-muzyczny-krys.jpg"),
  require("../../assets/images/sponsors/yamaha.jpg"),
  require("../../assets/images/sponsors/ck.png"),
  require("../../assets/images/sponsors/pik.jpg"),
  require("../../assets/images/sponsors/klimar.png"),
  require("../../assets/images/sponsors/pintura.png"),
  require("../../assets/images/sponsors/prezydent.png"),
]);

const SponsorsScreen = () => (
  <section className={styles.wrapper} id="sponsors">
    {/* <p>zadanie współfinansowane ze środków gminy - miasto Grudziądz</p> */}
    <Title>Wydarzenie wspierają</Title>
    {/*<Title>Zadanie współfinansowane ze środków gminy - miasto Grudziądz</Title> */}
    <div className={styles.sponsors}>
      {sponsors.map((logo, index) => (
        <img
          className={`${styles.logo} to-animate`}
          src={logo}
          alt={`logo number ${index}`}
          key={index}
        />
      ))}
    </div>
  </section>
);

export default SponsorsScreen;
