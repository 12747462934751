import React from "react";
import PropType from "prop-types";
import styles from "./Title.module.scss";

const Title = ({ children, standard }) => (
  <h2 className={`${styles.title} to-animate ${standard && styles.titleStandartFont}`}>
    {children}
  </h2>
);

Title.propTypes = {
  children: PropType.string.isRequired,
  standard: PropType.bool,
};

Title.defaultProps = {
  standard: false,
};

export default Title;
