import React from 'react';
import styles from './ParallaxBackground.module.scss';
import Rellax from 'rellax';
import PropType from 'prop-types';

class ParallaxBackground extends React.Component {
  constructor(props) {
    super(props);
    setTimeout(() => {
      new Rellax(this.rellaxRef, {
        center: true,
        speed: -3
      });
    }, 1);
  }

  render() {
    const image = this.props.image;
    const secondary = this.props.secondary;
    const style = this.props.style;

    return (
      <div
        className={secondary ? styles.parallaxSecondary : styles.parallax}
        style={{ backgroundImage: `url(${image})`, ...style }}
        ref={ref => {
          this.rellaxRef = ref;
        }}
      />
    );
  }
}

ParallaxBackground.propTypes = {
  image: PropType.string.isRequired,
  secondary: PropType.bool,
  style: PropType.object
};

ParallaxBackground.propsDefauls = {
  secondary: false
};

export default ParallaxBackground;
