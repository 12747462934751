import React from 'react';
import styles from './ClickMe.module.scss';
import { animateScroll } from 'react-scroll'


class ClickMe extends React.Component {
  handleClick = () => {
    animateScroll.scrollTo(window.innerHeight, { duration: 700 });
  }

  render() {
    return (
      <div
        className={styles.wrapper}
        onClick={this.handleClick}
      >
        <div className={styles.button}>Dowiedz się więcej</div>
        <div className={styles.arrow}>
          <svg
            width="12"
            height="66"
            viewBox="0 0 12 66"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="6" y1="0.6" x2="6" y2="60" stroke="white" strokeWidth="4" />
            <path d="M6 66L0.804 52.5H11.1962L6 66Z" fill="white" />
          </svg>
        </div>
      </div>
    );
  }
}

export default ClickMe