import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

import Screen from "./components/Screen/Screen";
import Logo from "./components/Logo/Logo";
import MenuShadow from "./components/MenuShadow/MenuShadow";

import GreeterScreen from "./components/GreeterScreen/GreeterScreen";

import AboudImage from "./assets/images/bg2.png";
// import BandsImage from "./assets/images/bg3.png";
import TicketsImage from "./assets/images/accordion.png";
import Title from "./components/Title/Title";
import Subtitle from "./components/Subtitle/Subtitle";
import SponsorsScreen from "./components/SponsorsScreen/SponsorsScreen";
import Menu from "./components/Menu/Menu";

import buczkowski from './assets/images/buczkowski.jpg';
import czarnina from './assets/images/czarnina.jpeg';
import sweet from "./assets/images/sweet.jpg";

class App extends React.Component {
  constructor() {
    super();
    setTimeout(() => {
      this.slideIn(document.querySelectorAll(".to-animate"));
    }, 100);
    document.addEventListener("scroll", () => {
      this.slideIn(document.querySelectorAll(".to-animate"));
    });
  }

  slideIn(objects) {
    for (let i = 0; i < objects.length; i += 1) {
      const item = objects[i];
      const bounding = item.getBoundingClientRect();
      if (bounding.y > 0 && bounding.y < window.innerHeight + 100) {
        item.classList.add("slideInUp");
      } else if (bounding.y > window.innerHeight - 100) {
        item.classList.remove("slideInUp");
      }
    }
  }

  render() {
    return (
      <>
        <MenuShadow />
        <Logo />
        <Menu />
        <GreeterScreen />

        <Screen id="about" bgImage={AboudImage}>
          <>
            <Title>17 listopada 2024</Title>
            <Title>16:00</Title>
            <Subtitle>
              <span>Ul. Wybickiego 38 - Klub {'"Akcent"'}</span>
            </Subtitle>
          </>
        </Screen>

        <article id="bands">
          <Screen id="tekla" bgImage={czarnina}>
            <>
              <Title>CZARNINA</Title>
              <Subtitle>
                akordeon „gypsy” w autorskich aranżacjach ludowej muzyki cygańskiej.
              </Subtitle>
            </>
          </Screen>
          <Screen id="wieslawtrio" bgImage={buczkowski}>
            <>
              <Title>PIAZZOLLA-REKONSTRUKCJE</Title>
              <Subtitle>
                czyli zespół wybitnych muzyków jazzowej sceny polskiej: Jarek Buczkowski – akordeon, Dawid Troczewski – fortepian, organy Hammonda , Zbigniew Wrombel – kontrabas , Marcin Jahr – perkusja
              </Subtitle>
            </>
          </Screen>
          <Screen id="sweet" bgImage={sweet}>
            <>
              <Title>Sweet Accordion Quartet</Title>
              <Subtitle>
                <span>
                  Grupa prezentująca muzykę akordeonową w różnych stylach, w autorskim opracowaniu;
                  tym razem w odsłonie przebojów muzyki europejskiej, z
                  {' "domieszką" vocalu, "pazura" '}
                  latynoskiego i beztroskim musette
                </span>
              </Subtitle>
            </>
          </Screen>
        </article>

        <Screen id="tickets" bgImage={TicketsImage} secondary>
          <>
            <Title standard>
              <span>
                BILETY W CENIE <span style={{ fontSize: "1.6em" }}>40 zł</span> DO NABYCIA W
              </span>
            </Title>
            <Subtitle>
              <>
                <span>- Klub "Akcent"</span>
                <span>- Centrum Kultury "TEATR"</span>
              </>
            </Subtitle>
          </>
        </Screen>

        <SponsorsScreen />
      </>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
