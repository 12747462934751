import React from 'react';
import LogoImage from '../../assets/images/logo.png';
import styles from './Logo.module.scss';

class Logo extends React.Component {
  constructor() {
    super();
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const el = document.getElementById('logo-image')
    const changePoint = el.clientHeight;
    const windowPosition = window.scrollY;

    if (windowPosition > changePoint) {
      el.classList.add(styles.scrolled);
    } else {
      el.classList.remove(styles.scrolled);
    }
  }

  render() {
    return (
      <div
        id="logo-image"
        className={styles.wrapper}
        style={{ backgroundImage: `url(${LogoImage})` }}
      ></div>
    )
  }
}

export default Logo;